import React from "react"
import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import Button from "shared/components/Button/index.legacy.js"
import Loader from "shared/components/Loader"
import CampaignInviteWrapper from "mill/containers/CampaignInviteWrapper"
import Imgix from "mill/components/Imgix"
import { ImageWindow, ContentWindow, Inner, HelpLinks } from "../styles"
import styled from "styled-components"
import colors from "shared/utils/colors"

export const HelpText = styled.small`
  margin-top: 3rem;
  display: block;
  color: ${colors.grayAluminium};
  font-size: 1.5rem;
`

import FETCH_BASIC_LEARNER_DETAILS from "mill/graphql/FetchBasicLearnerDetails"
import FETCH_CAMPAIGN_ID_FROM_INVITE_TOKEN from "mill/graphql/FetchCampaignIdFromInviteToken"

const CampaignInviteWelcomeBack = props => {
  const { userId, inviteToken } = useParams()
  const redirectPath = `/invite/campaign/${inviteToken}/enrol`

  const { data: campaignData, loading: campaignLoading } = useQuery(
    FETCH_CAMPAIGN_ID_FROM_INVITE_TOKEN,
    {
      variables: {
        inviteToken: inviteToken
      }
    }
  )

  const { data: learnerData, loading: learnerLoading, error } = useQuery(
    FETCH_BASIC_LEARNER_DETAILS,
    {
      variables: {
        id: userId
      }
    }
  )

  if (learnerLoading || campaignLoading) return <Loader />
  if (error) return <p>Error :(</p>
  const { name, email, currentCampaignId } = learnerData.basicLearnerDetails
  const learnerOnCampaign =
    currentCampaignId === campaignData.campaignFromInviteToken.id
  const loginBaseUrl = `/login?hint=${email}`

  return (
    <CampaignInviteWrapper>
      <ImageWindow portrait>
        <Imgix
          srcPath="static/campaign-invite/Seneca-opening-door.png"
          parameters="fit=fill&fill-color=0000"
          maxWidth={300}
          maxHeight={450}
        />
      </ImageWindow>
      <ContentWindow>
        <Inner paddingTop>
          <div>
            <h1>Welcome back {name}</h1>
            <p>
              {learnerOnCampaign
                ? "Looks like you are already on the campaign. Click through to sign in and continue."
                : `Looks like you already have a ${window.PLATFORM_NAME} account. Click through to sign in and start your campaign.`}
            </p>
            <Button
              data-qa="sign-in-url"
              secondary
              label="Sign in"
              href={
                learnerOnCampaign
                  ? loginBaseUrl
                  : `${loginBaseUrl}&redirect_to=${redirectPath}`
              }
            />
            <HelpText>
              Don’t worry, if you’ve forgotten your password you can always
              reset it.
            </HelpText>
          </div>
          <HelpLinks>
            <a href={window.SUPPORT_URL}>Need help?</a> |{" "}
            <a href="mailto:support@yarno.com.au">Contact support</a>
          </HelpLinks>
        </Inner>
      </ContentWindow>
    </CampaignInviteWrapper>
  )
}

export default CampaignInviteWelcomeBack
